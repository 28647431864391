<template>
    <div
        ref="customSelect"
        class="custom-select relative flex flex-col gap-2 w-full"
        @keydown="handleKeyDown"
        role="combobox"
        :aria-expanded="isOpen && !disabled"
        aria-haspopup="listbox"
        aria-owns="dropdown-list"
    >
        <slot name="icon-before"></slot>

        <FormInput
            v-if="isSearchable"
            v-model="searchQuery"
            @input="onInput"
            @focus="openDropdown"
            @click="openDropdown"
            type="text"
            :id="label"
            :name="label"
            :label="label"
            :placeholder="placeholder"
            class=""
            :labelStyle="labelstyle"
            autocapitalize="off"
            :disabled="disabled"
        />

        <button
            v-if="searchQuery && isSearchable && !disabled"
            @click="clearSearch"
            class="absolute right-2 bottom-0 transform -translate-y-[12px] text-stone-600"
            aria-label="Clear"
        >
            <CloseIcon />
        </button>

        <slot v-if="!isSearchable">
            <span class="font-subheading font-extrabold text-black-600 text-sm">
                {{ label }}
            </span>
        </slot>

        <slot name="icon-after"></slot>

        <div
            v-if="!isSearchable"
            :class="`relative w-full border border-stone-900 bg-selectBG ${selectWrapperClasses}`"
            @click="toggleDropdown"
        >
            <DynamicButton
                variant="text"
                size="none"
                color="transparent"
                :additionalClasses="computedButtonClasses"
                aria-label="Open dropdown"
                aria-controls="dropdown-list"
                :aria-expanded="isOpen"
                :disabled="disabled"
            >
                {{ selectedLabel }}
                <template
                    #icon-after
                    v-if="
                        selectedValue && selectedValue !== defaultSelectedValue
                    "
                >
                    <CompleteIcon size="size-4" />
                </template>
                <template #icon-after v-else>
                    <DownArrowIcon />
                </template>
            </DynamicButton>
        </div>

        <ul
            v-if="isOpen && filteredOptions.length && !disabled"
            ref="dropdown"
            class="absolute z-10 w-full border border-stone-900 bg-ivory-900 max-h-60 h-fit overflow-auto shadow-lg"
            tabindex="-1"
            role="listbox"
            :style="{
                top: dropdownTop,
                bottom: dropdownDirection === 'up' ? '100%' : 'auto',
            }"
        >
            <slot
                name="dropdown"
                :options="filteredOptions"
                :selectOption="selectOption"
                :isOpen="isOpen"
            >
                <li
                    v-for="(option, index) in filteredOptions"
                    :key="index"
                    :class="getOptionClasses(option)"
                    @click="selectOption(option)"
                    @keydown="handleOptionKeyDown(option, index)"
                    role="option"
                    :aria-selected="option.value === selectedValue"
                    :tabindex="index === 0 ? 0 : -1"
                >
                    {{ option.label }}
                </li>
            </slot>
        </ul>
    </div>

    <p v-if="required && !selectedValue" class="text-red-500 text-xs mt-1">
        {{ requiredMessage }}
    </p>
</template>

<script setup lang="ts">
import {
    ref,
    computed,
    watch,
    nextTick,
    onMounted,
    onBeforeUnmount,
} from "vue";

import type { Option } from "@/types";
import DownArrowIcon from "@/components/icons/DownArrowIcon.vue";
import FormInput from "@/components/forms/FormInput.vue";
import DynamicButton from "./DynamicButton.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import CompleteIcon from "@/components/icons/CompleteIcon.vue";

interface Props {
    label: string;
    options: Option[];
    labelstyle?: string;
    modelValue?: string | number | null | undefined | boolean;
    buttonClasses?: string;
    placeholder?: string;
    optionClasses?: string | ((option: Option) => string);
    isSearchable?: boolean;
    dropdownDirection?: "up" | "down";
    disabled?: boolean;
    required?: boolean;
    requiredMessage?: string;
}

let typedInput = "";
let inputTimeout: ReturnType<typeof setTimeout> | null = null;

const emit = defineEmits(["update:modelValue"]);

const props = defineProps<Props>();

const isOpen = ref(false);
const searchQuery = ref("");
const selectedValue = ref(props.modelValue);
const defaultSelectedValue = ref<string | number | boolean | null | undefined>(
    null
);
const customSelect = ref<HTMLElement | null>(null);
const dropdown = ref<HTMLElement | null>(null);
const dropdownTop = ref<string>("0px");
const dropdownDirection = ref<"up" | "down">(props.dropdownDirection || "down");

const filteredOptions = computed(() => {
    if (props.isSearchable && searchQuery.value) {
        return props.options.filter((option) =>
            option.label.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
    }
    return props.options;
});

watch(
    () => props.modelValue,
    (newValue) => {
        selectedValue.value = newValue;
        searchQuery.value =
            filteredOptions.value.find((option) => option.value === newValue)
                ?.label || "";
    }
);

const handleKeyDown = (event: KeyboardEvent) => {
    if (props.disabled) return;
    switch (event.key) {
        case "ArrowDown":
            event.preventDefault();
            focusNextOption();
            break;
        case "ArrowUp":
            event.preventDefault();
            focusPreviousOption();
            break;
        case "Enter":
            if (isOpen.value) {
                const focusedOption = document.activeElement as HTMLElement;
                focusedOption.click();
            } else {
                toggleDropdown();
            }
            break;
        case "Escape":
            closeDropdown();
            break;
        default:
            handleTypeToSelect(event.key);
            break;
    }
};

const handleTypeToSelect = (key: string) => {
    if (props.disabled) return;

    if (inputTimeout) {
        clearTimeout(inputTimeout);
    }

    typedInput += key.toLowerCase();

    inputTimeout = setTimeout(() => {
        typedInput = "";
    }, 1000);

    const matchingOptionIndex = props.options.findIndex((option) =>
        option.label.toLowerCase().startsWith(typedInput)
    );

    if (matchingOptionIndex !== -1) {
        focusOptionByIndex(matchingOptionIndex);
    }
};

const focusOptionByIndex = (index: number) => {
    const options = dropdown.value?.querySelectorAll('[role="option"]');
    if (options) {
        (options[index] as HTMLElement).focus();
    }
};

const selectedLabel = computed(() => {
    const selectedOption = props.options.find(
        (option) => option.value === selectedValue.value
    );
    return selectedOption
        ? selectedOption.label
        : props.placeholder || "Select an option";
});

const computedButtonClasses = computed(() => {
    return `flex justify-between items-center text-black-600 normal-case font-normal gap-2 w-full px-4 py-1 leading-10 min-w-full text-base md:text-[14px] ${
        props.buttonClasses || ""
    }`;
});

const requiredMessage = computed(() => {
    return props.requiredMessage || "This field is required";
});

const selectWrapperClasses = computed(() => {
    return isOpen.value ? "rounded-t-md border-b-0" : "rounded-md";
});

const toggleDropdown = async () => {
    if (props.disabled) return;

    isOpen.value = !isOpen.value;

    if (isOpen.value && props.isSearchable) {
        searchQuery.value = "";
    }

    await nextTick();

    if (isOpen.value && customSelect.value) {
        dropdownTop.value = `${customSelect.value.clientHeight}px`;
        // adjustDropdownPosition();
    }
};

const openDropdown = async () => {
    if (props.disabled) return;

    isOpen.value = true;
    await nextTick();
    if (customSelect.value) {
        dropdownTop.value = `${customSelect.value.clientHeight}px`;
        // adjustDropdownPosition();
    }
};

const adjustDropdownPosition = () => {
    if (dropdown.value && customSelect.value) {
        const dropdownRect = dropdown.value.getBoundingClientRect();
        const customSelectRect = customSelect.value.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const spaceBelow = viewportHeight - customSelectRect.bottom;

        if (spaceBelow < dropdownRect.height) {
            dropdownDirection.value = "up";
            dropdownTop.value = `-${dropdownRect.height}px`;
        } else {
            dropdownDirection.value = "down";
            dropdownTop.value = `${customSelectRect.height}px`;
        }
    }
};

const closeDropdown = () => {
    isOpen.value = false;
};

const selectOption = (option: Option) => {
    if (props.disabled) return;

    selectedValue.value = option.value;
    searchQuery.value = option.label;
    emit("update:modelValue", option.value);
    closeDropdown();
};

const onInput = () => {
    if (!isOpen.value) openDropdown();
};

const getOptionClasses = (option: Option) => {
    const baseClasses =
        "cursor-pointer select-none list-none relative px-4 py-3 text-sm text-black-600 hover:bg-ivory-100 focus:outline-none focus:bg-ivory-100";
    const additionalClasses =
        typeof props.optionClasses === "function"
            ? (props.optionClasses as (option: Option) => string)(option)
            : props.optionClasses || "";
    const selectedClass =
        selectedValue.value === option.value
            ? " bg-ivory-100 text-black-600"
            : "";
    return `${baseClasses} ${additionalClasses} ${selectedClass}`;
};

const handleClickOutside = (event: MouseEvent) => {
    if (
        customSelect.value &&
        !customSelect.value.contains(event.target as Node)
    ) {
        closeDropdown();
    }
};

const handleOptionKeyDown =
    (option: Option, index: number) => (event: KeyboardEvent) => {
        switch (event.key) {
            case "Enter":
                selectOption(option);
                break;
            case "ArrowDown":
                event.preventDefault();
                focusNextOption(index);
                break;
            case "ArrowUp":
                event.preventDefault();
                focusPreviousOption(index);
                break;
        }
    };

const focusNextOption = (currentIndex = 0) => {
    const options = dropdown.value?.querySelectorAll('[role="option"]');
    if (options) {
        const nextIndex = (currentIndex + 1) % options.length;
        (options[nextIndex] as HTMLElement).focus();
    }
};

const focusPreviousOption = (currentIndex = 0) => {
    const options = dropdown.value?.querySelectorAll('[role="option"]');
    if (options) {
        const prevIndex = (currentIndex - 1 + options.length) % options.length;
        (options[prevIndex] as HTMLElement).focus();
    }
};

const clearSearch = () => {
    searchQuery.value = "";
};

onMounted(() => {
    if (defaultSelectedValue.value === null) {
        defaultSelectedValue.value = props.modelValue;
    }

    document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
    document.removeEventListener("click", handleClickOutside);
});
</script>

<style scoped lang="scss">
.dropdown {
    transition: top 0.3s ease, bottom 0.3s ease;
}
:deep(button) {
    .ripple {
        @apply hidden;
    }

    span {
        @apply justify-start flex-1 overflow-hidden;
    }
}
</style>

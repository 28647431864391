<script setup lang="ts">
import { markRaw, ref, onMounted, watch, computed, onUnmounted } from "vue";
import { RouterView, useRoute } from "vue-router";
import { useHead } from "@unhead/vue";
import { Notivue, Notification, NotificationProgress } from "notivue";
import { lightTheme, type NotivueTheme } from "notivue";
import { useLoadingStore } from "@/stores/loading";
import { useSystemStore } from "./stores/system/system";
import { useResetStore } from "@/helpers/useResetStore";
import { useBookingStore } from "@/stores/booking/booking";
import { storeToRefs } from "pinia";
import { useThemeStore } from "@/stores/theme";
import HeaderBar from "@/components/global/HeaderBar.vue";
import NoticeIcon from "@/components/icons/NoticeIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import GlobalNotice from "@/components/global/GlobalNotice.vue";
import PopupModel from "@/components/global/PopupModel.vue";
import DynamicButton from "./components/forms/DynamicButton.vue";
import router from "./router";
import CountdownModal from "@/components/global/CountdownModal.vue";
import LoadingLogo from "@/components/global/LoadingLogo.vue";
import DefaultLogo from "@/components/logos/DefaultLogo.vue";
import HoarcrosshallLogo from "@/components/logos/HoarcrosshallLogo.vue";
import EdenHallLogo from "@/components/logos/EdenHallLogo.vue";

const props = defineProps<{
    theme: string;
}>();

const themeStore = useThemeStore();

const bookingStore = useBookingStore();
const { state: bookingState } = storeToRefs(bookingStore);
const systemStore = useSystemStore();
const loadingStore = useLoadingStore();
const isLoading = ref(loadingStore.isLoading);
const currentTheme = ref("default");

const beIcons = {
    success: markRaw(NoticeIcon),
    info: markRaw(NoticeIcon),
    error: markRaw(NoticeIcon),
    warning: markRaw(NoticeIcon),
    promise: null,
    close: markRaw(CloseIcon),
};

const computedBaronsTheme = computed<NotivueTheme>(() => {
    const primaryColor =
        currentTheme.value === "edenhall" ? "#77271A" : "#17352C";
    return {
        ...lightTheme,
        "--nv-radius": "0px",
        "--nv-width": "350px",
        "--nv-success-accent": primaryColor,
        "--nv-error-accent": "#7f1a1a",
        "--nv-error-fg": "#7f1a1a",
        "--nv-error-bg": "#F8E2DA",
        "--nv-warning-accent": primaryColor,
        "--nv-info-accent": primaryColor,
        "--nv-promise-accent": primaryColor,
        "--nv-global-bg": "#F1EDE6",
        "--nv-global-fg": primaryColor,
        "--nv-global-accent": primaryColor,
        "--nv-global-border": "#C6C4BE",
        "--nv-y-align-has-title": "top",
        "--nv-y-align": "top",
        "--nv-title-size": "1rem",
        "--nv-shadow":
            "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    };
});

const noticeScrolling = ref<boolean>(true);
const noticeMessage = ref<string>(
    "This is a banner to display temporary messaging such as system issues or even upsell"
);

const systemOffline = ref(false);

const checkSystemStatus = async () => {
    try {
        await systemStore.systemStatus();

        if (!systemStore.state.systemStatus) {
            systemOffline.value = true;
        } else {
            systemOffline.value = false;
        }

        if (
            bookingStore.state.booking.id &&
            systemStore.state.remainingTimeoutMinutes === 0
        ) {
            const resetStore = useResetStore();
            await resetStore.all();
            await systemStore.systemStatus();

            const redirectExcludes = [
                "/login",
                "/register",
                "/password-reset",
                "/pre-arrival",
                "/",
                "/itinerary",
                "/404",
                "/book/build",
            ];

            const isExcluded = redirectExcludes.some((exclude) => {
                const regex = new RegExp(
                    `^${exclude.replace(/\//g, "\\/")}($|\\/.*)`
                );
                return regex.test(route.path);
            });

            if (!isExcluded) {
                router.push({ name: "Index" });
            }
        }

        if (
            route.name !== "BookingBuilder" &&
            systemStore.state.systemStatus &&
            !systemStore.state.webfolioId &&
            !systemStore.state.sessionId
        ) {
            await createSessionWithRetry();
        }
    } catch (error) {
        console.error("Error fetching system status", error);
    }
};

const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 1000;

const createSessionWithRetry = async (attempts = MAX_RETRY_ATTEMPTS) => {
    for (let i = 0; i < attempts; i++) {
        try {
            await systemStore.systemStatus();
            await systemStore.createSession();
            return;
        } catch (error) {
            console.error(`Session creation failed on attempt ${i + 1}`, error);

            if (i < attempts - 1) {
                const delay = RETRY_DELAY * Math.pow(2, i);
                await new Promise((resolve) => setTimeout(resolve, delay));
            } else {
                console.error("Max session creation attempts reached");
            }
        }
    }
};

const route = useRoute();

const logoComponents: { [key: string]: any } = {
    default: DefaultLogo,
    hoarcrosshall: HoarcrosshallLogo,
    edenhall: EdenHallLogo,
};

const currentLogoComponent = computed(
    () => logoComponents[currentTheme.value] || DefaultLogo
);

watch(
    () => loadingStore.isLoading,
    (newVal) => {
        isLoading.value = newVal;
    }
);

watch(
    () => route.meta,
    (meta) => {
        if (meta) {
            useHead({
                title: (meta.title as string) || "Barons Eden",
                meta: [
                    {
                        name: "description",
                        content:
                            (meta.description as string) ||
                            "Fallback description",
                    },
                    {
                        property: "og:title",
                        content:
                            (meta.ogTitle as string) || "Fallback OG Title",
                    },
                    {
                        property: "og:description",
                        content:
                            (meta.ogDescription as string) ||
                            "Fallback OG Description",
                    },
                    {
                        property: "og:image",
                        content:
                            (meta.ogImage as string) || "/fallback-image.jpg",
                    },
                ],
            });
        }
    },
    { immediate: true }
);

watch(
    () => bookingState.value.booking.venue_id,
    (newVenueId) => {
        let theme = "default";
        if (newVenueId === 100) {
            theme = "hoarcrosshall";
        } else if (newVenueId === 300) {
            theme = "edenhall";
        }
        themeStore.setTheme(theme);
        currentTheme.value = theme;
        document.documentElement.className = theme;
    },
    { immediate: true }
);

const themeData = computed(() => {
    const themes: { [key: string]: any } = {
        default: {
            address: {
                name: "Head Office",
                addressLine1: "Genesis House 7 Cotswold Business Village",
                addressLine2:
                    "London Road, Moreton-In-Marsh, Gloucestershire, GL56 0JQ",
            },
            socialLinks: [
                {
                    label: "LinkedIn",
                    url: "https://www.linkedin.com/company/the-barons-eden-group-limited",
                },
                {
                    label: "Google",
                    url: "https://www.google.com/maps/place/Barons+Eden/",
                },
            ],
        },
        hoarcrosshall: {
            address: {
                name: "Hoar Cross Hall Spa Hotel",
                addressLine1: "Maker Lane, Hoar Cross",
                addressLine2: "Burton upon Trent, Staffordshire, DE13 8QS",
            },
            socialLinks: [
                {
                    label: "Facebook",
                    url: "https://www.facebook.com/hoarcrosshall",
                },
                {
                    label: "Instagram",
                    url: "https://www.instagram.com/hoarcrosshallhotel/",
                },
                {
                    label: "Trip Advisor",
                    url: "https://www.tripadvisor.co.uk/Hotel_Review-Hoar_Cross_Hall_Hotel",
                },
                {
                    label: "Google",
                    url: "https://www.google.co.uk/maps/place/Hoar+Cross+Hall/",
                },
            ],
        },
        edenhall: {
            address: {
                name: "Eden Hall Day Spa",
                addressLine1: "Lodge Lane, Elston",
                addressLine2: "Newark, Nottinghamshire, NG23 5PG",
            },
            socialLinks: [
                {
                    label: "Facebook",
                    url: "https://www.facebook.com/EdenHallSpa",
                },
                {
                    label: "Instagram",
                    url: "https://www.instagram.com/edenspanotts/",
                },
                {
                    label: "Trip Advisor",
                    url: "https://www.tripadvisor.co.uk/Attraction-Review-Eden_Hall_Day_Spa/",
                },
                {
                    label: "Google",
                    url: "https://www.google.com/maps/place/Eden+Hall+Day+Spa/",
                },
            ],
        },
    };
    return themes[currentTheme.value];
});

const navigateHome = () => {
    router.push({ name: "Index" });
};

const storeSourceParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const awaid = urlParams.get("awaid");
    const utm_source = urlParams.get("utm_source");
    const gclid = urlParams.get("gclid");
    const source = urlParams.get("source");
    const awc = urlParams.get("awc");

    if (awaid) localStorage.setItem("awaid", awaid);
    if (utm_source) localStorage.setItem("utm_source", utm_source);
    if (gclid) localStorage.setItem("gclid", gclid);
    if (source) localStorage.setItem("source", source);
    if (awc) localStorage.setItem("awc", awc);
};

const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
        checkSystemStatus();
    }
};

onUnmounted(() => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
});

onMounted(async () => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    checkSystemStatus();
    themeStore.setTheme(currentTheme.value);
    storeSourceParams();
});
</script>

<template>
    <div
        id="wrapper"
        class="flex flex-col main-container min-h-screen h-full w-screen"
        :class="`theme-${currentTheme}`"
    >
        <HeaderBar :theme="currentTheme" />

        <GlobalNotice
            :message="noticeMessage"
            :scrolling="noticeScrolling"
            :speed="30"
        />

        <CountdownModal />

        <PopupModel
            :visible="systemOffline && !systemStore.state.isLoading"
            title="Thank you for your patience"
        >
            <p>
                Our website is experiencing high demand at the moment, and
                bookings are temporarily unavailable. Please contact our
                reservations team at <strong>01283 576522</strong>. We’re here
                to help.
            </p>

            <template #buttons>
                <DynamicButton
                    @click="checkSystemStatus"
                    variant="outline"
                    aria-label="Try again"
                >
                    Try Again
                </DynamicButton>
            </template>
        </PopupModel>

        <div
            :class="`relative w-screen flex flex-col flex-1 md:flex-0 theme-${themeStore.currentTheme}`"
        >
            <router-view v-slot="{ Component }">
                <component :is="Component" />
            </router-view>
        </div>

        <div
            class="footer w-screen bg-ivory-900 py-8 px-4 pb-24 | print:hidden"
        >
            <div
                class="container flex flex-col items-start gap-4 md:flex-row md:justify-between md:gap-8 md:max-w-[900px]"
            >
                <div
                    class="w-full flex flex-col items-start gap-2 text-xs font-subheading md:w-1/2"
                >
                    <p class="mb-0">
                        <strong>{{ themeData.address.name }}</strong
                        ><br />
                        {{ themeData.address.addressLine1 }}<br />
                        {{ themeData.address.addressLine2 }}
                    </p>
                </div>

                <div
                    class="footerlogo w-full md:w-1/6 mt-4 md:mt-0 flex justify-center md:justify-start"
                >
                    <div
                        class="flex items-center logo cursor-pointer w-full max-w-60"
                        @click="navigateHome"
                    >
                        <component :is="currentLogoComponent" />
                        <span class="sr-only">{{
                            $t("headerBar.logoText")
                        }}</span>
                    </div>
                </div>
            </div>

            <div
                class="container flex flex-col items-start border-t border-solid border-stone-100 pt-4 mt-8 md:mt-4 md:flex-row md:max-w-[900px] md:items-end md:justify-between"
            >
                <div
                    class="text-xxs text-stone-900 font-subheading flex flex-col items-start gap-1"
                >
                    <p class="mb-0">
                        &copy; {{ new Date().getFullYear() }} Barons Eden Group
                    </p>
                    <p>
                        Barons Eden Limited. Registered in England and Wales No.
                        09121021.
                    </p>
                </div>

                <div
                    class="legal flex flex-col md:flex-row items-start md:items-center gap-2 mt-4 md:mt-0"
                >
                    <DynamicButton
                        href="https://baronseden.com/privacy"
                        target="_blank"
                        variant="text"
                        color="primary"
                        size="none"
                        additionalClasses="text-xs capitalize"
                    >
                        Privacy Policy
                    </DynamicButton>
                    <DynamicButton
                        v-if="currentTheme === 'hoarcrosshall'"
                        href="https://baronseden.com/hoar-cross-hall/terms-conditions"
                        target="_blank"
                        variant="text"
                        color="primary"
                        size="none"
                        additionalClasses="text-xs capitalize"
                    >
                        Terms & Conditions
                    </DynamicButton>
                    <DynamicButton
                        v-if="currentTheme === 'edenhall'"
                        href="https://baronseden.com/eden-hall/terms-conditions"
                        target="_blank"
                        variant="text"
                        color="primary"
                        size="none"
                        additionalClasses="text-xs capitalize block"
                    >
                        Terms & Conditions
                    </DynamicButton>
                </div>
            </div>
        </div>
    </div>

    <Notivue v-slot="item">
        <Notification
            :item="item"
            :theme="computedBaronsTheme"
            :icons="beIcons"
        >
            <NotificationProgress :item="item" />
        </Notification>
    </Notivue>

    <Transition name="fade" appear>
        <LoadingLogo
            v-if="isLoading"
            :theme="currentTheme"
            :loading="isLoading"
        />
    </Transition>
</template>

<style scoped lang="scss">
:root,
.theme-default {
    --color-primary: #858074;
    --color-primary-hover: #908c81;
    --color-secondary: #8f7e4a;
    --color-secondary-hover: #998a5a;
    --color-text: #000000;
    --color-accent: #bab8b1;
    --color-highlight: #c6c4be;
    --color-border: #858074;
    --color-border-hover: #908c81;
}

.theme-hoarcrosshall {
    --color-primary: #17352c;
    --color-primary-hover: #2c473f;
    --color-secondary: #8f7e4a;
    --color-secondary-hover: #998a5a;
    --color-text: #000000;
    --color-accent: #92a09c;
    --color-highlight: #92a09c;
    --color-border: #17352c;
    --color-border-hover: #2c473f;
}

.theme-edenhall {
    --color-primary: #77271a;
    --color-primary-hover: #792a1d;
    --color-secondary: #8f7e4a;
    --color-secondary-hover: #998a5a;
    --color-text: #000000;
    --color-accent: #9c776f;
    --color-highlight: #9c776f;
    --color-border: #77271a;
    --color-border-hover: #792a1d;
}

:deep() {
    .page-enter-active,
    .page-leave-active {
        transition: all 0.8s ease-in-out;
    }

    .page-enter-from,
    .page-leave-to {
        opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active {
        @apply transition-opacity duration-500 ease-in-out;
    }

    .fade-enter-from,
    .fade-leave-to {
        @apply opacity-0;
    }

    .fade-enter-to,
    .fade-leave-from {
        @apply opacity-100;
    }

    .fade-slide-enter-active,
    .fade-slide-leave-active {
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    }

    .fade-slide-enter-from,
    .fade-slide-leave-to {
        transform: translateY(100%);
        opacity: 0;

        @media (min-width: 768px) {
            transform: translateY(0);
        }
    }

    .fade-slide-enter-to,
    .fade-slide-leave-from {
        transform: translateY(0);
        opacity: 1;
    }

    .slide-enter-active,
    .slide-leave-active {
        @apply transition-all duration-500 ease-in-out;
    }

    .slide-enter-from,
    .slide-leave-to {
        @apply translate-y-full;
    }

    .slide-down-enter-active,
    .slide-down-leave-active {
        @apply transition-all duration-500 ease-in-out;
    }

    .slide-down-enter-from,
    .slide-down-leave-to {
        @apply max-h-0;
    }

    .slide-left-enter-active,
    .slide-left-leave-active {
        @apply transition-all duration-500 ease-in-out;
    }

    .slide-left-enter-from,
    .slide-left-leave-to {
        @apply translate-x-full;
    }

    .slide-fade-enter-active,
    .slide-fade-leave-active {
        transition: transform 0.5s ease, opacity 0.5s ease;
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateX(-5%);
        opacity: 0;
    }

    .slide-fade-enter-to,
    .slide-fade-leave-from {
        transform: translateX(0);
        opacity: 1;
    }

    .fade-slide-offset-enter-active,
    .fade-slide-offset-leave-active {
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    }

    .fade-slide-offset-enter-from,
    .fade-slide-offset-leave-to {
        transform: translateY(100%);
    }

    .fade-slide-offset-enter-to,
    .fade-slide-offset-leave-from {
        transform: translateY(0);
    }

    .filter-enter-active,
    .filter-leave-active {
        @apply transition-all duration-500 ease-in-out;
    }

    .filter-enter-from,
    .filter-leave-to {
        @apply translate-y-full md:translate-y-0 md:translate-x-full;
    }
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import { useLoadingStore } from "@/stores/loading";
import Index from "@/pages/IndexPage.vue";
import { usePreArrivalStore } from "@/stores/preArrival/preArrival";
import { useBookingStore } from "@/stores/booking/booking";
import { emitEvent } from "@/helpers/eventEmitter";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "Index",
            component: Index,
            meta: {
                title: "Bookings at Barons Eden | Barons Eden Group",
                description:
                    "Secure your Hotel Stay, Spa Day, Treatments, or Afternoon Tea at Barons Eden today. Enjoy elegance and relaxation at its finest.",
                ogTitle: "Bookings at Barons Eden | Barons Eden Group",
                ogDescription:
                    "Secure your Hotel Stay, Spa Day, Treatments, or Afternoon Tea at Barons Eden today. Enjoy elegance and relaxation at its finest.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/book/:bookingType/:venue/:experience?/:startDate?/:nights?/:guests?",
            name: "BookingBuilder",
            component: () => import("../pages/BookingBuilder.vue"),
            meta: {
                title: "Bookings at Barons Eden | Barons Eden Group",
                description:
                    "Secure your Hotel Stay, Spa Day, Treatments, or Afternoon Tea at Barons Eden today. Enjoy elegance and relaxation at its finest.",
                ogTitle: "Bookings at Barons Eden | Barons Eden Group",
                ogDescription:
                    "Secure your Hotel Stay, Spa Day, Treatments, or Afternoon Tea at Barons Eden today. Enjoy elegance and relaxation at its finest.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/experience/stays",
            name: "Stay Experiences",
            component: () => import("../pages/Stays/Experiences.vue"),
            meta: {
                gates: [
                    "bookingCompleted",
                    "bookingLocked",
                    { name: "experienceOnly", options: { experience: "stay" } },
                ],
                title: "Spa Stay at {{location}} | Barons Eden Group",
                description:
                    "Book your experience at {{location}} for a perfect Spa stay escape.",
                ogTitle: "Spa Stay at {{location}} | Barons Eden Group",
                ogDescription:
                    "Book your experience at {{location}} for a perfect Spa stay escape.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/experience/stays/treatments/inclusives",
            name: "Stays Inclusive Treatments",
            component: () => import("../pages/Stays/InclusiveTreatments.vue"),
            meta: {
                gates: [
                    "bookingCompleted",
                    {
                        name: "experienceOnly",
                        options: {
                            experience: "stay",
                        },
                    },
                ],
                title: "Spa Stay at {{location}} - Treatments | Barons Eden Group",
                description:
                    "Select your treatment for a complete Spa stay experience at {{location}}.",
                ogTitle:
                    "Spa Stay at {{location}} - Treatments | Barons Eden Group",
                ogDescription:
                    "Select your treatment for a complete Spa stay experience at {{location}}.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/experience/stays/treatments/additional",
            name: "Stays Additional Treatments",
            component: () => import("../pages/Stays/AdditionalTreatments.vue"),
            meta: {
                gates: [
                    "bookingCompleted",
                    "inclusivesCompleted",
                    { name: "experienceOnly", options: { experience: "stay" } },
                ],
                title: "Spa Stay at {{location}} - Treatments | Barons Eden Group",
                description:
                    "Select your treatment for a complete Spa stay experience at {{location}}.",
                ogTitle:
                    "Spa Stay at {{location}} - Treatments | Barons Eden Group",
                ogDescription:
                    "Select your treatment for a complete Spa stay experience at {{location}}.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/experience/spa-day",
            name: "Spa Day Experiences",
            component: () => import("../pages/SpaDays/Experiences.vue"),
            meta: {
                gates: [
                    "bookingCompleted",
                    "bookingLocked",
                    {
                        name: "experienceOnly",
                        options: { experience: "spa_day" },
                    },
                ],
                title: "Bookings at {{location}} | Barons Eden",
                description:
                    "Reserve your Spa Day, Treatments, or Relaxation Treatments at {{location}}. Discover the ultimate in rejuvenation.",
                ogTitle: "Bookings at {{location}} | Barons Eden",
                ogDescription:
                    "Reserve your Spa Day, Treatments, or Relaxation Treatments at {{location}}. Discover the ultimate in rejuvenation.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/experience/spa-day/treatments/inclusives",
            name: "Spa Day Inclusive Treatments",
            component: () => import("../pages/SpaDays/InclusiveTreatments.vue"),
            meta: {
                gates: [
                    "bookingCompleted",
                    {
                        name: "experienceOnly",
                        options: {
                            experience: "spa_day",
                        },
                    },
                ],
                title: "Spa Day at {{location}} - Treatments | Barons Eden Group",
                description:
                    "Select your treatment for a complete Spa day experience at {{location}}.",
                ogTitle:
                    "Spa Day at {{location}} - Treatments | Barons Eden Group",
                ogDescription:
                    "Select your treatment for a complete Spa day experience at {{location}}.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/experience/spa-day/treatments/additional",
            name: "Spa Day Additional Treatments",
            component: () =>
                import("../pages/SpaDays/AdditionalTreatments.vue"),
            meta: {
                gates: [
                    "bookingCompleted",
                    "inclusivesCompleted",
                    {
                        name: "experienceOnly",
                        options: { experience: "spa_day" },
                    },
                ],
                title: "Spa Day at {{location}} - Treatments | Barons Eden Group",
                description:
                    "Select your treatment for a complete Spa day experience at {{location}}.",
                ogTitle:
                    "Spa Day at {{location}} - Treatments | Barons Eden Group",
                ogDescription:
                    "Select your treatment for a complete Spa day experience at {{location}}.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/experience/spa-evening",
            name: "Spa Evening Experiences",
            component: () => import("../pages/SpaDays/Experiences.vue"),
            meta: {
                gates: [
                    "bookingCompleted",
                    "bookingLocked",
                    {
                        name: "experienceOnly",
                        options: { experience: "spa_evening" },
                    },
                ],
                title: "Spa Evening at {{location}} | Barons Eden Group",
                description:
                    "Book your experience at {{location}} for a perfect Spa evening escape.",
                ogTitle: "Spa Evening at {{location}} | Barons Eden Group",
                ogDescription:
                    "Book your experience at {{location}} for a perfect Spa evening escape.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/experience/spa-evening/treatments/inclusives",
            name: "Spa Evening Inclusive Treatments",
            component: () => import("../pages/SpaDays/InclusiveTreatments.vue"),
            meta: {
                gates: [
                    "bookingCompleted",
                    {
                        name: "experienceOnly",
                        options: {
                            experience: "spa_evening",
                        },
                    },
                ],
                title: "Spa Evening at {{location}} - Treatments | Barons Eden Group",
                description:
                    "Select your treatment for a complete Spa evening experience at {{location}}.",
                ogTitle:
                    "Spa Evening at {{location}} - Treatments | Barons Eden Group",
                ogDescription:
                    "Select your treatment for a complete Spa evening experience at {{location}}.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/experience/spa-evening/treatments/additional",
            name: "Spa Evening Additional Treatments",
            component: () =>
                import("../pages/SpaDays/AdditionalTreatments.vue"),
            meta: {
                gates: [
                    "bookingCompleted",
                    "inclusivesCompleted",
                    {
                        name: "experienceOnly",
                        options: { experience: "spa_evening" },
                    },
                ],
                title: "Spa Evening at {{location}} - Treatments | Barons Eden Group",
                description:
                    "Select your treatment for a complete Spa evening experience at {{location}}.",
                ogTitle:
                    "Spa Evening at {{location}} - Treatments | Barons Eden Group",
                ogDescription:
                    "Select your treatment for a complete Spa evening experience at {{location}}.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/experience/treatments",
            name: "Treatments",
            component: () => import("../pages/TreatmentsPage.vue"),
            meta: {
                gates: [
                    "bookingCompleted",
                    {
                        name: "experienceOnly",
                        options: { experience: "treatments" },
                    },
                ],
                title: "Treatments at {{location}} | Barons Eden Group",
                description: "Book a treatment for a great experience.",
                ogTitle: "Treatments at {{location}} | Barons Eden Group",
                ogDescription: "Book a treatment for a great experience.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/experience/afternoon-tea",
            name: "Afternoon Tea",
            component: () => import("../pages/AfternoonTeaPage.vue"),
            meta: {
                gates: [
                    "bookingCompleted",
                    "hasDiningReservation",
                    {
                        name: "experienceOnly",
                        options: { experience: "dining" },
                    },
                ],
                title: "Afternoon Tea | Barons Eden",
                description:
                    "Indulge in a delightful Afternoon Tea experience at Barons Eden. Book your table for a sophisticated treat.",
                ogTitle: "Afternoon Tea | Barons Eden",
                ogDescription:
                    "Indulge in a delightful Afternoon Tea experience at Barons Eden. Book your table for a sophisticated treat.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/experience/gift-cards",
            name: "Gift Cards",
            component: () => import("../pages/GiftCardsPage.vue"),
            meta: {
                gates: ["bookingCompleted"],
                title: "Gift Cards | Barons Eden Group",
                description:
                    "Give the gift of luxury with one of our gift cards.",
                ogTitle: "Gift Cards | Barons Eden Group",
                ogDescription:
                    "Give the gift of luxury with one of our gift cards.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/experience/confirmation",
            name: "Order Confirmation",
            component: () => import("../pages/OrderConfirmation.vue"),
            meta: {
                gates: ["bookingIncomplete"],
                title: "Order Confirmation | Barons Eden Group",
                description:
                    "Thank you for your order. Your order has been confirmed.",
                ogTitle: "Order Confirmation | Barons Eden Group",
                ogDescription:
                    "Thank you for your order. Your order has been confirmed.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/itinerary/:bookingRef/:download?",
            name: "Your Itinerary",
            component: () => import("../pages/BookingItinerary.vue"),
            meta: {
                title: "Your Itinerary | Barons Eden Group",
                description: "Your booking itinerary for your upcoming visit.",
                ogTitle: "Your Itinerary | Barons Eden Group",
                ogDescription:
                    "Your booking itinerary for your upcoming visit.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/login",
            name: "Login",
            component: () => import("../components/auth/Login.vue"),
            props: (route) => ({
                redirectUrl: route.query.redirect || route.query.redirectUrl,
            }),
            meta: {
                gates: ["guest"],
                title: "Login | Barons Eden Group",
                description: "Login or sign-up to finish your booking.",
                ogTitle: "Login | Barons Eden Group",
                ogDescription: "Login or sign-up to finish your booking.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/register",
            name: "Register",
            component: () => import("../components/auth/Register.vue"),
            props: (route) => ({
                redirectUrl: route.query.redirect || route.query.redirectUrl,
            }),
            meta: {
                gates: ["guest"],
                title: "Register | Barons Eden Group",
                description:
                    "Create an account to manage your bookings and profile.",
                ogTitle: "Register | Barons Eden Group",
                ogDescription:
                    "Create an account to manage your bookings and profile.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/password-reset/:token?",
            name: "Password Reset",
            component: () => import("../pages/PasswordReset.vue"),
            meta: {
                gates: ["guest"],
                title: "Password Reset | Barons Eden Group",
                description:
                    "Request a password reset email to reset your password.",
                ogTitle: "Password Reset | Barons Eden Group",
                ogDescription:
                    "Request a password reset email to reset your password.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/my-account/complete-registration",
            name: "Complete Registration",
            component: () =>
                import("../components/auth/CompleteRegistration.vue"),
            props: (route) => ({
                redirectUrl: route.query.redirect || route.query.redirectUrl,
            }),
            meta: {
                gates: ["auth"],
                title: "Complete Registration | Barons Eden Group",
                description:
                    "Complete your registration to manage your bookings and profile.",
                ogTitle: "Complete Registration | Barons Eden Group",
                ogDescription:
                    "Complete your registration to manage your bookings and profile.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/my-account/profile",
            name: "Profile",
            component: () => import("../pages/Account/EditProfile.vue"),
            meta: {
                gates: ["auth"],
                title: "Profile | Barons Eden Group",
                description:
                    "Update your profile details and manage your account.",
                ogTitle: "Profile | Barons Eden Group",
                ogDescription:
                    "Update your profile details and manage your account.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/my-account/address",
            name: "Address",
            component: () => import("../pages/Account/EditAddress.vue"),
            meta: {
                gates: ["auth"],
                title: "Address | Barons Eden Group",
                description:
                    "Update your address details and manage your account.",
                ogTitle: "Address | Barons Eden Group",
                ogDescription:
                    "Update your address details and manage your account.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/my-account/security",
            name: "Security",
            component: () => import("../pages/Account/EditSecurity.vue"),
            meta: {
                gates: ["auth"],
                title: "Security | Barons Eden Group",
                description:
                    "Update your security details and manage your account.",
                ogTitle: "Security | Barons Eden Group",
                ogDescription:
                    "Update your security details and manage your account.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/experience/checkout",
            name: "Checkout",
            component: () => import("../pages/CheckoutPage.vue"),
            meta: {
                gates: [
                    "auth",
                    "bookingCompleted",
                    "bookingHasCartItems",
                    "completeRegistration",
                    "inclusivesCompleted",
                ],
                title: "Checkout | Barons Eden Group",
                description: "",
                ogTitle: "Checkout | Barons Eden Group",
                ogDescription:
                    "Complete your registration to manage your bookings and profile.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/pre-arrival/:bookingRef",
            name: "Pre Arrival Consultation",
            component: () => import("../pages/PreArrivalPage.vue"),
            props: (route) => ({
                bookingRef: route.params.bookingRef,
            }),
            meta: {
                title: "Pre Arrival Consultation | Barons Eden Group",
                description:
                    "Complete your Pre Arrival Consultation to ensure a smooth experience.",
                ogTitle: "Pre Arrival Consultation | Barons Eden Group",
                ogDescription:
                    "Complete your Pre Arrival Consultation to ensure a smooth experience.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/pre-arrival/guest/:bookingRef",
            name: "Pre Arrival Consultation Guest Form",
            component: () => import("../pages/PreArrivalGuestPage.vue"),
            props: (route) => ({
                bookingRef: route.params.bookingRef,
            }),
            meta: {
                gates: ["preArrivalAuth"],
                preArrivalGuest: true,
                title: "Pre Arrival Consultation | Barons Eden Group",
                description:
                    "Complete your Pre Arrival Consultation to ensure a smooth experience.",
                ogTitle: "Pre Arrival Consultation | Barons Eden Group",
                ogDescription:
                    "Complete your Pre Arrival Consultation to ensure a smooth experience.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
        {
            path: "/:pathMatch(.*)*",
            name: "NotFound",
            component: () => import("../pages/404.vue"),
            meta: {
                statusCode: 404,
                title: "404 | Barons Eden Group",
                description:
                    "Sorry, the page you are looking for could not be found.",
                ogTitle: "404 | Barons Eden Group",
                ogDescription:
                    "Sorry, the page you are looking for could not be found.",
                ogImage:
                    "https://barons-eden-cdn.fra1.digitaloceanspaces.com/media/images/OCU4Ol3z9C4bSn9xfdZMDkb7oDh7AL6WGIqwh3mw.webp",
            },
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

function updateMeta(location: number | null, meta: any) {
    const locationName = location === 300 ? "Eden Hall" : "Hoar Cross Hall";

    let ogImage;
    if (location === 300) {
        ogImage =
            "https://barons-eden-cdn.fra1.digitaloceanspaces.com/open-graph/Open_Graph_Eden_Hall_1.webp";
    } else if (location === 100) {
        ogImage =
            "https://barons-eden-cdn.fra1.digitaloceanspaces.com/open-graph/Open_Graph_Hoar_Cross_Hall_1.webp";
    } else {
        ogImage =
            "https://barons-eden-cdn.fra1.digitaloceanspaces.com/open-graph/Open_Graph_Barons_Eden_1.webp";
    }

    return {
        ...meta,
        title: meta.title.replace("{{location}}", locationName),
        ogTitle: meta.ogTitle.replace("{{location}}", locationName),
        description: meta.description.replace("{{location}}", locationName),
        ogDescription: meta.ogDescription.replace("{{location}}", locationName),
        ogImage: ogImage,
    };
}

router.beforeEach((to, from, next) => {
    const loadingStore = useLoadingStore();
    const bookingStore = useBookingStore();

    loadingStore.setLoading(true);

    if (to.meta.preArrivalGuest) {
        const preArrivalStore = usePreArrivalStore();
        preArrivalStore.state.guestBookingRef = String(to.params.bookingRef);
    }

    to.meta = updateMeta(bookingStore.state.booking.venue_id, to.meta);

    emitEvent("analytics-event", {
        type: "page_view",
        page: to.name,
        title: to.meta.title,
        page_location: to.fullPath,
        page_referrer: from.fullPath,
    });

    if (to.query.reset) {
        next({ name: "Password Reset" });
    } else {
        next();
    }
});

router.afterEach((to) => {
    const loadingStore = useLoadingStore();
    loadingStore.setLoading(false);
});

export default router;

<template>
    <div class="cart-item p-4 bg-cart" v-if="item.pms_folio_id">
        <div
            class="flex gap-3 justify-between items-center pb-2 border-b border-stone-100"
        >
            <h2
                class="font-subheading font-semibold uppercase text-sm line-clamp-1"
                role="heading"
                aria-level="2"
            >
                {{ dayBooking ? item.rate_details?.title : title }}
            </h2>
            <p
                class="font-subheading text-[16px] font-semibold"
                aria-live="polite"
            >
                £{{ item.total }}
            </p>
        </div>
        <div
            class="flex justify-between items-center mb-4 text-sm mt-4"
            v-if="!dayBooking && item.room_details"
        >
            <IconWithText
                iconPosition="top"
                variant="autowidth"
                role="listitem"
                aria-label="Number of guests"
            >
                <template #icon>
                    <GuestIcon />
                </template>
                <span class="font-sans text-xxs font-light line-clamp-1">
                    {{ item.guests?.length }}
                    {{ $t("common.guests") }}
                </span>
            </IconWithText>
            <IconWithText
                iconPosition="top"
                variant="autowidth"
                role="listitem"
                aria-label="Experience"
            >
                <template #icon>
                    <ExperienceCartIcon />
                </template>
                <span class="font-sans text-xxs font-light line-clamp-1">
                    {{ item.rate_details?.title }}</span
                >
            </IconWithText>
            <IconWithText
                iconPosition="top"
                variant="autowidth"
                role="listitem"
                aria-label="Room"
            >
                <template #icon>
                    <CartRoomIcon />
                </template>
                <span class="font-sans text-xxs font-light line-clamp-1">
                    {{
                        item.room_details?.title || item.room_details?.room_type
                    }}
                </span>
            </IconWithText>
        </div>

        <div v-if="!dayBooking">
            <div
                class="mb-2"
                v-for="(guest, index) in item.guests"
                :key="guest.id"
            >
                <div v-if="guest.inclusive_treatments.length > 0">
                    <p
                        v-if="index === 0"
                        class="font-subheading font-bold uppercase text-xs mb-1"
                    >
                        {{ $t("cartItem.inclusiveTreatmentsLabelSimple") }}
                    </p>
                    <ul class="flex flex-col gap-2">
                        <li
                            v-for="(item, index) in guest.inclusive_treatments"
                            :key="index"
                            class="flex justify-between gap-2"
                            role="listitem"
                        >
                            <span
                                class="flex-auto flex flex-col font-serif text-sm tracking-tight leading-5 line-clamp-1"
                            >
                                <span>
                                    {{
                                        truncateText(
                                            item.treatment.custom_title ??
                                                item.treatment.title,
                                            35
                                        )
                                    }}
                                </span>
                                <span>
                                    {{
                                        new Date(
                                            item.start_time
                                        ).toLocaleString("en-GB", {
                                            weekday: "long",
                                        })
                                    }}
                                    {{
                                        new Date(
                                            item.start_time
                                        ).toLocaleTimeString("en-GB", {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                        })
                                    }}
                                    -
                                    {{
                                        new Date(
                                            new Date(
                                                item.start_time
                                            ).getTime() +
                                                item.treatment.service_time *
                                                    60000
                                        ).toLocaleTimeString("en-GB", {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                        })
                                    }}
                                </span>
                            </span>

                            <div class="flex flex-col justify-end items-end">
                                <DynamicButton
                                    @click="
                                        removeTreatment(guest.id, item, true)
                                    "
                                    variant="text"
                                    size="none"
                                    aria-label="Remove inclusive treatment"
                                >
                                    <span
                                        class="text-danger-700 text-xs capitalize underline"
                                    >
                                        {{ $t("common.remove") }}
                                    </span>
                                </DynamicButton>
                                <span v-if="item.treatment.price > 0"
                                    >£{{ item.treatment.price }}</span
                                >
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div
                class="mb-2"
                v-for="(guest, index) in item.guests"
                :key="guest.id"
            >
                <div v-if="guest.additional_treatments.length > 0">
                    <p
                        v-if="index === 0"
                        class="font-subheading font-bold uppercase text-xs mb-1"
                    >
                        {{ $t("cartItem.additionalsLabelSimple") }}
                    </p>
                    <ul class="flex flex-col gap-2">
                        <li
                            v-for="(item, index) in guest.additional_treatments"
                            :key="index"
                            class="flex justify-between gap-2"
                            role="listitem"
                        >
                            <span
                                class="flex-auto flex flex-col font-serif text-sm tracking-tight leading-5 line-clamp-1"
                            >
                                <span>
                                    {{
                                        truncateText(
                                            item.treatment.custom_title ??
                                                item.treatment.title,
                                            33
                                        )
                                    }}
                                </span>
                                <span>
                                    {{
                                        new Date(
                                            item.start_time
                                        ).toLocaleString("en-GB", {
                                            weekday: "long",
                                        })
                                    }}
                                    {{
                                        new Date(
                                            item.start_time
                                        ).toLocaleTimeString("en-GB", {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                        })
                                    }}
                                    -
                                    {{
                                        new Date(
                                            new Date(
                                                item.start_time
                                            ).getTime() +
                                                item.treatment.service_time *
                                                    60000
                                        ).toLocaleTimeString("en-GB", {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                        })
                                    }}</span
                                >
                            </span>

                            <div class="flex flex-col items-end">
                                <DynamicButton
                                    @click="
                                        removeTreatment(guest.id, item, false)
                                    "
                                    variant="text"
                                    size="none"
                                    aria-label="Remove additional treatment"
                                >
                                    <span
                                        class="text-danger-700 text-xs capitalize underline"
                                    >
                                        {{ $t("common.remove") }}
                                    </span>
                                </DynamicButton>
                                <span
                                    class="text-[14px] font-medium font-subheading"
                                    >£{{ item.total }}</span
                                >
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div
                class="mb-4"
                v-for="(guest, index) in item.guests"
                :key="guest.id"
            >
                <div v-if="guest.special_services.length > 0">
                    <p
                        v-if="index === 0"
                        class="font-subheading font-bold uppercase text-sm mb-2"
                    >
                        {{ $t("cartItem.finishingTouchesLabelSimple") }}
                    </p>
                    <ul class="flex flex-col gap-2">
                        <li
                            v-for="(item, index) in guest.special_services"
                            :key="index"
                            class="flex justify-between gap-2"
                            role="listitem"
                        >
                            <span
                                class="flex-auto flex flex-col font-serif text-sm tracking-tight leading-5 line-clamp-1"
                            >
                                {{
                                    truncateText(item.special_service.title, 20)
                                }}
                            </span>
                            <span
                                class="text-[14px] font-medium font-subheading"
                                >£{{ item.total }}</span
                            >

                            <DynamicButton
                                @click="removeSpecialService(item)"
                                variant="text"
                                size="none"
                                aria-label="Remove special service"
                            >
                                <span
                                    class="text-danger-700 text-xs capitalize underline"
                                >
                                    {{ $t("common.remove") }}
                                </span>
                            </DynamicButton>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="mb-4">
                <div
                    v-if="
                        item.special_services &&
                        item.special_services.length > 0 &&
                        item.special_services[0].special_service
                    "
                >
                    <p class="font-subheading font-bold uppercase text-xs mb-2">
                        {{ $t("cartItem.finishingTouchesLabelSimple") }}
                    </p>
                    <ul class="flex flex-col gap-2">
                        <li
                            v-for="(
                                specialService, index
                            ) in item.special_services"
                            :key="index"
                            class="flex justify-between gap-2"
                            role="listitem"
                        >
                            <span
                                class="flex-auto flex flex-col font-serif text-sm tracking-tight leading-5 line-clamp-1"
                            >
                                {{
                                    truncateText(
                                        specialService.special_service.title,
                                        20
                                    )
                                }}
                            </span>
                            <div
                                class="flex flex-row-reverse items-center gap-2 items-end"
                            >
                                <DynamicButton
                                    @click="
                                        removeSpecialService(specialService)
                                    "
                                    variant="text"
                                    size="none"
                                    aria-label="Remove special service"
                                >
                                    <span
                                        class="text-danger-700 text-xs capitalize underline"
                                    >
                                        {{ $t("common.remove") }}
                                    </span>
                                </DynamicButton>
                                <span
                                    class="text-[14px] font-medium font-subheading"
                                    >£{{ specialService.total }}</span
                                >
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="mt-4 mb-4">
                <div
                    v-if="
                        item.inclusive_treatments &&
                        item.inclusive_treatments.length > 0
                    "
                >
                    <p class="font-subheading font-bold uppercase text-xs mb-2">
                        {{ $t("cartItem.inclusiveTreatmentsLabelSimple") }}
                    </p>

                    <ul class="flex flex-col gap-2">
                        <li
                            v-for="(
                                inclusiveItem, index
                            ) in item.inclusive_treatments"
                            :key="index"
                            class="flex justify-between gap-2"
                            role="listitem"
                        >
                            <span
                                class="flex-auto flex flex-col font-serif text-sm tracking-tight leading-5 line-clamp-1"
                            >
                                <span>
                                    {{
                                        truncateText(
                                            inclusiveItem.treatment
                                                .custom_title ??
                                                inclusiveItem.treatment.title,
                                            35
                                        )
                                    }}
                                </span>
                                <span>
                                    {{
                                        new Date(
                                            inclusiveItem.start_time
                                        ).toLocaleString("en-GB", {
                                            weekday: "long",
                                        })
                                    }}
                                    {{
                                        new Date(
                                            inclusiveItem.start_time
                                        ).toLocaleTimeString("en-GB", {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                        })
                                    }}
                                    -
                                    {{
                                        new Date(
                                            new Date(
                                                inclusiveItem.start_time
                                            ).getTime() +
                                                inclusiveItem.treatment
                                                    .service_time *
                                                    60000
                                        ).toLocaleTimeString("en-GB", {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                        })
                                    }}
                                </span>
                            </span>
                            <div class="flex flex-col items-end">
                                <DynamicButton
                                    @click="
                                        removeTreatment(
                                            null,
                                            inclusiveItem,
                                            true
                                        )
                                    "
                                    variant="text"
                                    size="none"
                                    aria-label="Remove inclusive treatment"
                                >
                                    <span
                                        class="text-danger-700 text-xs capitalize underline"
                                    >
                                        {{ $t("common.remove") }}
                                    </span>
                                </DynamicButton>
                                <span v-if="inclusiveItem.treatment.price > 0"
                                    >£{{ inclusiveItem.treatment.price }}</span
                                >
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="mb-4">
                <div
                    v-if="
                        item.additional_treatments &&
                        item.additional_treatments.length > 0
                    "
                >
                    <p class="font-subheading font-bold uppercase text-xs mb-2">
                        {{ $t("cartItem.additionalsLabelSimple") }}
                    </p>
                    <ul class="flex flex-col gap-2">
                        <li
                            v-for="(
                                additionalItem, index
                            ) in item.additional_treatments"
                            :key="index"
                            class="flex justify-between gap-2"
                            role="listitem"
                        >
                            <span
                                class="flex-auto flex flex-col font-serif text-sm tracking-tight leading-5 line-clamp-1"
                            >
                                <span>
                                    {{
                                        truncateText(
                                            additionalItem.treatment
                                                .custom_title ??
                                                additionalItem.treatment.title,
                                            35
                                        )
                                    }}
                                </span>
                                <span>
                                    {{
                                        new Date(
                                            additionalItem.start_time
                                        ).toLocaleString("en-GB", {
                                            weekday: "long",
                                        })
                                    }}
                                    {{
                                        new Date(
                                            additionalItem.start_time
                                        ).toLocaleTimeString("en-GB", {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                        })
                                    }}
                                    -
                                    {{
                                        new Date(
                                            new Date(
                                                additionalItem.start_time
                                            ).getTime() +
                                                additionalItem.treatment
                                                    .service_time *
                                                    60000
                                        ).toLocaleTimeString("en-GB", {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                        })
                                    }}</span
                                >
                            </span>

                            <div class="flex flex-col items-end">
                                <DynamicButton
                                    @click="
                                        removeTreatment(
                                            null,
                                            additionalItem,
                                            false
                                        )
                                    "
                                    variant="text"
                                    size="none"
                                    aria-label="Remove additional treatment"
                                >
                                    <span
                                        class="text-danger-700 text-xs capitalize underline"
                                    >
                                        {{ $t("common.remove") }}
                                    </span>
                                </DynamicButton>
                                <span
                                    class="text-[14px] font-medium font-subheading"
                                    >£{{ additionalItem.total }}</span
                                >
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="mb-4">
                <div
                    v-if="
                        item.special_services &&
                        item.special_services.length > 0
                    "
                >
                    <p class="font-subheading font-bold uppercase text-xs mb-2">
                        {{ $t("cartItem.finishingTouchesLabelSimple") }}
                    </p>
                    <ul class="flex flex-col gap-2">
                        <li
                            v-for="(
                                specialItem, index
                            ) in item.special_services"
                            :key="index"
                            class="flex justify-between gap-2"
                            role="listitem"
                        >
                            <span
                                class="flex-auto flex flex-col font-serif text-sm tracking-tight leading-5 line-clamp-1"
                            >
                                {{
                                    truncateText(
                                        specialItem.special_service.title,
                                        20
                                    )
                                }}
                            </span>
                            <div class="flex items-center gap-2">
                                <span
                                    class="text-[14px] font-medium font-subheading"
                                    >£{{ specialItem.total }}</span
                                >

                                <DynamicButton
                                    @click="removeSpecialService(specialItem)"
                                    variant="text"
                                    size="none"
                                    aria-label="Remove special service"
                                >
                                    <span
                                        class="text-danger-700 text-xs capitalize underline"
                                    >
                                        {{ $t("common.remove") }}
                                    </span>
                                </DynamicButton>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="mb-4" v-if="item.promo_items.length > 0">
            <p class="font-subheading font-bold uppercase text-sm mb-2">
                {{ $t("common.discount") }}
            </p>
            <ul>
                <li
                    v-for="(promoItem, index) in item.promo_items"
                    :key="index"
                    class="flex justify-between gap-2"
                    role="listitem"
                >
                    <span class="flex-auto">Promo code</span>
                    <span>£{{ promoItem.total }}</span>
                </li>
            </ul>
        </div>

        <DynamicButton
            v-if="item.pms_folio_id"
            @click="removePmsItem"
            variant="text"
            size="none"
            aria-label="Remove PMS item"
        >
            <span class="text-danger-700 text-xs capitalize underline">
                {{ $t(removeItemText) }}
            </span>
        </DynamicButton>
    </div>

    <PopupModel
        :visible="confirmationModalVisible"
        :title="$t('reviewBooking.confirmTitle')"
        @close="confirmationModalVisible = false"
    >
        {{ $t("reviewBooking.confirmMessage") }}
        <template #buttons>
            <DynamicButton
                variant="solid"
                @click="confirmRemoval(true)"
                additionalClasses="mt-4"
                aria-label="Close guest selection modal"
            >
                {{ $t("common.remove") }}
            </DynamicButton>
            <DynamicButton
                variant="outline"
                @click="confirmRemoval(false)"
                aria-label="Close guest selection modal"
            >
                {{ $t("common.cancel") }}
            </DynamicButton>
        </template>
    </PopupModel>

    <LoadingLogo
        v-if="isLoading"
        :loading="isLoading"
        :message="$t('common.updatingBooking')"
    />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import type {
    BookingPmsItem,
    BookingSpaItem,
    BookingSpecialServiceItem,
} from "@/types";
import { usePmsStore } from "@/stores/pms/pms";
import { useBookingStepsStore } from "@/stores/booking/bookingSteps";
import { useSpaStore } from "@/stores/spa/spa";
import { useBookingStore } from "@/stores/booking/booking";
import IconWithText from "@/components/global/IconWithText.vue";
import DynamicButton from "@/components/forms/DynamicButton.vue";
import GuestIcon from "@/components/icons/GuestIcon.vue";
import ExperienceCartIcon from "@/components/icons/ExperienceCartIcon.vue";
import CartRoomIcon from "@/components/icons/CartRoomIcon.vue";
import PopupModel from "@/components/global/PopupModel.vue";
import LoadingLogo from "@/components/global/LoadingLogo.vue";

interface Props {
    title: string;
    item: BookingPmsItem;
    dayBooking: boolean;
}

const props = defineProps<Props>();

const pmsStore = usePmsStore();
const bookingStore = useBookingStore();
const bookingStepsStore = useBookingStepsStore();
const spaStore = useSpaStore();
const confirmationModalVisible = ref(false);
const resolveConfirmation = ref<((isConfirmed: boolean) => void) | null>(null);
const isLoading = ref(false);

const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
    }
    return text;
};

const confirmRemoval = (isConfirmed: boolean) => {
    confirmationModalVisible.value = false;
    if (resolveConfirmation.value) {
        resolveConfirmation.value(isConfirmed);
    }
};

const showConfirmationModal = () => {
    confirmationModalVisible.value = true;

    return new Promise((resolve) => {
        resolveConfirmation.value = resolve;
    });
};

const removePmsItem = async (): Promise<void> => {
    try {
        if (!props.item.id || !props.item.pms_folio_id) {
            throw new Error("PMS item ID or PMS folio ID is missing");
        }

        const isConfirmed = await showConfirmationModal();
        if (!isConfirmed) {
            return;
        }

        isLoading.value = true;

        await pmsStore.removePmsBooking(
            null,
            props.item.id,
            props.item.pms_folio_id
        );

        isLoading.value = false;

        await bookingStepsStore.resetToStep(1);
    } catch (error) {
        console.error("Error during pms item removal", error);
        throw error;
    }
};

const removeTreatment = async (
    pmsItemGuestId: number | null,
    treatment: BookingSpaItem,
    isInclusive: boolean
) => {
    try {
        if (!props.item.id) {
            throw new Error("PMS item ID is missing");
        }

        const isConfirmed = await showConfirmationModal();

        if (!isConfirmed) {
            return;
        }

        isLoading.value = true;

        await spaStore.removeTreatment(
            treatment,
            props.item.id,
            pmsItemGuestId,
            treatment.treatment_id,
            treatment.spa_folio_id,
            treatment.spa_folio_item_id
        );

        isLoading.value = false;

        if (isInclusive) {
            await bookingStepsStore.resetToStep(2);
        }
    } catch (error) {
        console.error("Error during treatment removal", error);
        throw error;
    }
};

const removeSpecialService = async (
    specialService: BookingSpecialServiceItem
) => {
    try {
        if (!props.item.id) {
            throw new Error("PMS item ID is missing");
        }

        const isConfirmed = await showConfirmationModal();

        if (!isConfirmed) {
            return;
        }

        isLoading.value = true;

        await pmsStore.removePmsSpecialService(
            specialService,
            props.item.id,
            props.item.pms_folio_id,
            specialService.pms_folio_special_service_id,
            specialService.special_service.id
        );

        isLoading.value = false;
    } catch (error) {
        console.error("Error during special service removal", error);
        throw error;
    }
};

const removeItemText = computed(() => {
    if (bookingStore.state.booking.experience === "afternoon_tea") {
        return "common.removeAfternoonTea";
    } else if (bookingStore.state.booking.experience === "spa_day") {
        return "common.removeSpaDay";
    } else if (bookingStore.state.booking.experience === "spa_evening") {
        return "common.removeSpaEvening";
    } else if (bookingStore.state.booking.experience === "stay") {
        return "common.removeRoom";
    } else {
        return "common.remove";
    }
});
</script>
